import styles from './WorkspaceMenuActions.module.css';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    WORKSPACE_CREATION,
    workspaceSettingsLink,
    workspaceUpgradeLink
} from '@he-novation/config/paths/herawFrontUris';
import { getLoginRoute } from '@he-novation/config/paths/herawLoginPaths';
import { ClientTier, ClientUserRole } from '@he-novation/config/types/db/enums';
import { ActionType } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { logout } from '@he-novation/front-shared/async/user.async';
import { asyncUpdateClientCurrentWorkspace } from '@he-novation/front-shared/async/workspace.async';
import { useAtom, useAtomValue, useSetAtom } from 'jotai/index';

import { folderClipboardAtom } from '$atoms/folder-atoms';
import { workspaceAtom, workspacesAtom } from '$atoms/workspace-atoms';
import {
    WorkspaceIcon,
    WorkspaceIconSize
} from '$components/Buttons/WorkspaceMenuButton/WorkspaceIcon';
import { useTranslate } from '$hooks/useTranslate';
import { useWorkspaceMembers } from '$hooks/useWorkspaceMembers';
import { set } from '$redux/route/routeActions';
import { currentUserUuidSelector, totpEnabledSelector } from '$redux/user/userSelectors';

export function useWorkspaceMenuActions(isSmallScreen?: boolean) {
    const dispatch = useDispatch();
    const workspaces = useAtomValue(workspacesAtom);
    const [currentWorkspace, setCurrentWorkspace] = useAtom(workspaceAtom);
    const { currentUserUuid } = useSelector(currentUserUuidSelector);

    const setCliploard = useSetAtom(folderClipboardAtom);

    const { t } = useTranslate();

    const { totpEnabled } = useSelector(totpEnabledSelector);
    const { fetchWorkspaceMembers } = useWorkspaceMembers();

    useEffect(() => {
        setCliploard(null);
    }, [currentWorkspace]);

    const workspaceMenuActions: ActionType[] =
        workspaces
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map((workspace) => {
                const name =
                    workspace.displayName.charAt(0).toUpperCase() + workspace.displayName.slice(1);
                return {
                    className: styles.workspaceOption,
                    disabled: workspace.uuid === currentWorkspace?.uuid,
                    children: (
                        <>
                            <WorkspaceIcon
                                name={workspace.displayName}
                                primary={workspace.primaryColor}
                                logoUrl={workspace.logoUrl}
                                className={styles.workspaceOptionIcon}
                                size={
                                    isSmallScreen
                                        ? WorkspaceIconSize.Small
                                        : WorkspaceIconSize.Medium
                                }
                            />
                            {name}
                        </>
                    ),
                    onClick: async () => {
                        await asyncUpdateClientCurrentWorkspace(workspace.name);
                        if (workspace.strictOIDCEnabled || (workspace.totpForced && !totpEnabled)) {
                            logout();
                        } else {
                            fetchWorkspaceMembers(workspace.name);
                        }
                        setCurrentWorkspace(workspace);
                        dispatch(set(getLoginRoute(workspace.name, workspace.features)));
                    }
                };
            }) || [];

    if (!workspaces?.find((w) => w.ownerUuid === currentUserUuid && w.tier === ClientTier.freemium))
        workspaceMenuActions.push({
            className: styles.workspaceOption,
            children: (
                <>
                    <WorkspaceIcon
                        name={'add-workspace'}
                        icon={'plus'}
                        className={styles.workspaceOptionIcon}
                        size={isSmallScreen ? WorkspaceIconSize.Small : WorkspaceIconSize.Medium}
                    />
                    {t('new.Create my workspace')}
                </>
            ),
            onClick: () => dispatch(set(WORKSPACE_CREATION))
        });

    if (currentWorkspace?.role === ClientUserRole.ADMINISTRATOR) {
        workspaceMenuActions.push({ separator: true });
        workspaceMenuActions.push({
            href: workspaceSettingsLink(currentWorkspace.name),
            icon: 'crown',
            children: t('common.Workspace administration')
        });
    }

    if (
        currentWorkspace?.role === ClientUserRole.ADMINISTRATOR &&
        currentWorkspace.tier === ClientTier.freemium
    ) {
        workspaceMenuActions.push({
            icon: 'upload',
            children: t('common.Upgrade'),
            onClick: () => {
                dispatch(set(workspaceUpgradeLink(currentWorkspace.name, 2)));
            }
        });
    }

    return {
        workspaceMenuActions,
        currentWorkspace,
        features: currentWorkspace?.features || []
    };
}
