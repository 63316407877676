import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FEEDBACK } from '@he-novation/config/paths/modals.constants';
import { Locale } from '@he-novation/config/types/i18n.types';
import { CustomFieldValuesBody } from '@he-novation/config/types/payloads/custom-field.payload';
import type { DroppedData } from '@he-novation/design-system/utils/fileSystemParsing';
import { asyncFetchClientStorage } from '@he-novation/front-shared/async/workspace.async';
import type { FrontFolderContent } from '@he-novation/front-shared/types/folder.front-types';
import { useAtomValue } from 'jotai';
import { v4 as uuidV4 } from 'uuid';
import { workspaceNameAtom } from '../atoms/workspace-atoms';
import { useWorkspaceStatus } from './useWorkspaceStatus';

import { Uploader, uploadFile, uploadSubtitles } from '$helpers/Uploader';
import { UploaderState, UploadFolder } from '$helpers/Uploader.types';
import { uploadTree } from '$helpers/uploadTree';
import { useModal } from '$hooks/useModal';
import { useTranslate } from '$hooks/useTranslate';
import { userInfosSelector } from '$redux/user/userSelectors';

export function useUploader() {
    const [state, setState] = useState<UploaderState>({
        uploads: [],
        pending: [],
        finished: [],
        errors: []
    });
    const uploader = Uploader;
    const { userInfos } = useSelector(userInfosSelector);

    const { t } = useTranslate();

    const { openModal } = useModal();
    const workspaceName = useAtomValue(workspaceNameAtom);
    const { canAddContent } = useWorkspaceStatus();

    useEffect(() => {
        Uploader.t = t;
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            if (uploader.uploads.length) {
                e.preventDefault();
                e.returnValue = '';
            }
        };
        window.addEventListener('beforeunload', onBeforeUnload);
        uploader.register(setState);
        return () => {
            uploader.unregister(setState);
            window.removeEventListener('beforeunload', onBeforeUnload);
        };
    }, []);

    const checkStorage = useCallback(async (size: number) => {
        const { available } = await asyncFetchClientStorage(workspaceName);

        if (available !== -1 && size > available) {
            const message = t("folder.You don't have enough available space.");
            openModal(FEEDBACK, {
                title: t('common.Upload failed'),
                message,
                isError: true
            });
            return false;
        }
        return true;
    }, []);

    return {
        ...state,
        uploadFile: async (
            file: File,
            folder: UploadFolder,
            parentFileUuid?: string,
            customFieldValues?: CustomFieldValuesBody
        ): Promise<string | void> => {
            if (!canAddContent()) {
                return 'PAYMENT_INCIDENT';
            }

            if (!(await checkStorage(file.size!))) return 'STORAGE';

            uploader.resetInvalidFiles();

            uploadFile(
                workspaceName,
                userInfos,
                {
                    uploadGroup: {
                        uuid: uuidV4(),
                        name: file.name
                    },
                    uploadIndex: 0,
                    uploadsTotal: 1,
                    file,
                    folder,
                    parentFileUuid
                },
                customFieldValues
            );
            return;
        },
        uploadSubtitles: async (
            file: File,
            fileUuid: string,
            fileVersion: number,
            locale: Locale
        ): Promise<string | void> => {
            uploader.resetInvalidFiles();

            return await uploadSubtitles(workspaceName, {
                file,
                fileUuid,
                fileVersion,
                locale
            });
        },
        uploadTree: async (
            userUuid: string,
            rootFolder: {
                uuid: string;
                name: string;
                metadata: object;
            },
            contentToUpload: DroppedData & { content?: FrontFolderContent | null },
            preferences: object = {}
        ): Promise<string | void | void[]> => {
            if (!canAddContent()) {
                return 'PAYMENT_INCIDENT';
            }
            if (!(await checkStorage(contentToUpload.size!))) return 'STORAGE';
            uploader.resetInvalidFiles();

            uploadTree(
                userInfos,
                workspaceName,
                userUuid,
                rootFolder,
                { ...contentToUpload, uuid: rootFolder.uuid, name: rootFolder.name, path: '/' },
                preferences,
                t
            );
            return;
        }
    };
}
