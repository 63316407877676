import type { IntegrationBody } from '@he-novation/config/types/payloads/integration.payload';
import {
    asyncIntegrationCreate,
    asyncIntegrationDelete,
    asyncIntegrationsFetch,
    asyncIntegrationUpdate,
    asyncPluginAuthUrlFetch
} from '@he-novation/front-shared/async/integration.async';
import { useAtom } from 'jotai';
import { userIntegrationsAtom } from '../atoms/user-atoms';

export function useUserIntegrations() {
    const [userIntegrations, setUserIntegrations] = useAtom(userIntegrationsAtom);
    return {
        userIntegrations,
        fetchIntegrations: () => asyncIntegrationsFetch().then(setUserIntegrations),
        async createUserIntegration(body: IntegrationBody) {
            const createdIntegration = await asyncIntegrationCreate(body);
            setUserIntegrations((prev) => [...prev, createdIntegration]);
            return createdIntegration;
        },
        updateUserIntegration: (integrationUuid: string, body: IntegrationBody) =>
            asyncIntegrationUpdate(integrationUuid, body).then((updatedIntegration) => {
                setUserIntegrations((prev) =>
                    prev.map((i) => (i.uuid === integrationUuid ? updatedIntegration : i))
                );
                return updatedIntegration;
            }),
        deleteUserIntegration: async (uuid: string) => {
            asyncIntegrationDelete(uuid).then(() => {
                setUserIntegrations((prev) => prev.filter((i) => i.uuid !== uuid));
            });
        },
        fetchIntegrationAuthUrl: (type: string) => asyncPluginAuthUrlFetch(type),
        updateIntegrationProperties(integrationUuid, props: Record<string, unknown>) {
            setUserIntegrations((prev) =>
                prev.map((integration) => {
                    if (integration.uuid === integrationUuid) {
                        return {
                            ...integration,
                            properties: {
                                ...integration.properties,
                                ...props
                            }
                        };
                    }
                    return integration;
                })
            );
        }
    };
}
