import { useSelector } from 'react-redux';
import {
    CLIENT_USER,
    CONFIRM,
    DELETE_CONFIRM,
    USER_TRANSFER,
    WORKSPACE_USER_LABELS
} from '@he-novation/config/paths/modals.constants';
import { ClientUser } from '@he-novation/config/types/client.types';
import { ClientUserRole } from '@he-novation/config/types/db/enums';
import type { WorkspaceUser } from '@he-novation/config/types/workspace-team.types';
import { ActionType } from '@he-novation/design-system/components/buttons/MultiActionButton/MultiActionButton';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import {
    asyncTransferWorkspaceOwnership,
    asyncWorkspaceUserDelete
} from '@he-novation/front-shared/async/workspace.async';
import {
    asyncAddUsersToWorkspaceTeam,
    asyncRemoveUserFromWorkspaceTeam
} from '@he-novation/front-shared/async/workspace-team.async';
import { getHighlightObject } from '@he-novation/front-shared/utils/propsToHighlightedSearchNode';
import objectSearchFactory from '@he-novation/utils/objectSearchFactory';
import { getDisplayName } from '@he-novation/utils/user';
import { useAtomValue } from 'jotai';
import { pick } from 'lodash/fp';

import { selectedWorkspaceMembersAtom } from '$atoms/selection-atoms';
import { workspaceAtom, workspaceNameAtom, workspaceRoleAtom } from '$atoms/workspace-atoms';
import { companyGrouperFactory, nameGrouperFactory } from '$components/DataLayout/grouperFunctions';
import { useModal } from '$hooks/useModal';
import { Translator, useTranslate } from '$hooks/useTranslate';
import { useWorkspaceMembers } from '$hooks/useWorkspaceMembers';
import { useWorkspaceTeams } from '$hooks/useWorkspaceTeams';
import { currentUserUuidSelector, preferencesSelector } from '$redux/user/userSelectors';

export const getWorkspaceMemberInfoGroupers = (t: Translator) => [
    {
        key: 'name',
        label: t('common.Name'),
        grouperFunction: nameGrouperFactory<WorkspaceUser>(t, 'lastname', 'firstname', 'email')
    },
    {
        key: 'firm',
        label: t('entity.Entity'),
        grouperFunction: companyGrouperFactory<WorkspaceUser>(t, 'firm')
    }
];

export function useDataLayoutWorkspaceMembersManagementActionsMenu() {
    const { t } = useTranslate();
    const { preferences } = useSelector(preferencesSelector);
    const { currentUserUuid } = useSelector(currentUserUuidSelector);
    const workspace = useAtomValue(workspaceAtom)!;

    const { openModal, closeModal } = useModal();

    return function itemToActions(item: ClientUser): ActionType[] {
        const actions: ActionType[] = [
            {
                children: t('common.Edit'),
                onClick: () => openModal(CLIENT_USER, { clientUser: item, preferences })
            }
        ];
        if (workspace.ownerUuid !== item.uuid) {
            actions.push({
                children: t('common.Delete'),
                onClick: () =>
                    openModal(DELETE_CONFIRM, {
                        message: `${t(
                            'settings.You are about to remove {{user}} for your workspace',
                            {
                                user: getDisplayName(item)
                            }
                        )}`,
                        cancel: t('common.Cancel'),
                        title: t('common.Delete'),
                        confirmButtonLabel: t('common.Delete'),
                        alert: true,
                        onCancel: () => openModal(USER_TRANSFER, { userSrc: item }),
                        onDelete: () =>
                            asyncWorkspaceUserDelete(workspace.name, item.uuid).then(closeModal)
                    })
            });
        }

        /*         if (featureACL.api(workspace.features)) {
            actions.push({
                children: t('settings.Display API key'),
                onClick: () =>
                    openModal(COPY_PASTE, { title: t('settings.API key'), value: item.apiKey })
            });
        } */

        if (
            workspace.ownerUuid === currentUserUuid &&
            item.workspaceRole !== ClientUserRole.BASIC
        ) {
            actions.push(
                { separator: true },
                {
                    children: t('settings.Transfer workspace ownership'),
                    onClick: () =>
                        openModal(CONFIRM, {
                            title: t('settings.Transfer ownership'),
                            message: t(
                                'settings.Are you sure you want to transfer workspace ownership to {{user}}?',
                                {
                                    user: getDisplayName(item, true, ['(', ')'])
                                }
                            ),
                            confirmButtonLabel: t('common.Confirm'),
                            alert: true,
                            displayLoader: true,
                            onSubmit: () =>
                                asyncTransferWorkspaceOwnership(workspace.name, item.uuid).then(
                                    closeModal
                                )
                        })
                }
            );
        }
        return actions;
    };
}

export function useDataLayoutWorkspaceMembersInfoActionsMenu(teamUuid?: string) {
    const workspaceRole = useAtomValue(workspaceRoleAtom);

    const { openModal } = useModal();

    const workspaceName = useAtomValue(workspaceNameAtom);
    const { workspaceMembers } = useWorkspaceMembers();
    const { workspaceTeams } = useWorkspaceTeams();
    const selected = useAtomValue(selectedWorkspaceMembersAtom);

    const { t } = useTranslate();

    return function itemToActions(item: WorkspaceUser): ActionType[] {
        const actions: ActionType[] = [];
        const selectedItems = workspaceMembers.filter((c) => selected.includes(c.uuid));
        if (!selectedItems.length) {
            selectedItems.push(item);
        }

        if (workspaceRole === ClientUserRole.ADMINISTRATOR) {
            if (teamUuid) {
                actions.push({
                    children: t('contact.Remove member from team'),
                    onClick: () =>
                        Promise.all(
                            selectedItems.map((item) =>
                                asyncRemoveUserFromWorkspaceTeam(workspaceName, teamUuid, item.uuid)
                            )
                        )
                });
            }

            if (workspaceTeams?.length) {
                actions.push({
                    children: t('contact.Add member to team'),
                    direction: [DirectionX.Right, DirectionY.Middle],
                    actions: workspaceTeams
                        .filter((t) => t.uuid != teamUuid)
                        .map(({ name, uuid }) => ({
                            children: name,
                            onClick: () =>
                                asyncAddUsersToWorkspaceTeam(
                                    workspaceName,
                                    uuid,
                                    selectedItems.map((i) => i.uuid)
                                )
                        }))
                });
            }

            if (actions.length) {
                actions.push({ separator: true });
            }

            actions.push({
                children: t('contact.Edit labels'),
                onClick: () => {
                    openModal(WORKSPACE_USER_LABELS, { user: item });
                }
            });
        }
        return actions;
    };
}

export const searchProperties = ['email', 'firstname', 'lastname', 'role', 'phone', 'firm'];
export const filterSearch = objectSearchFactory(searchProperties);

export const highlight = (item: WorkspaceUser, search?: string) =>
    getHighlightObject(pick(searchProperties)(item), search);
