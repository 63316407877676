import './DataLayout.scss';
import formStyles from '@he-novation/design-system/styles/form-styles.module.css';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { DataLayoutDisplayMode } from '@he-novation/config/types/dataLayout.types';
import { CheckboxList } from '@he-novation/design-system/components/form/CheckboxList/CheckboxList';
import { FormField } from '@he-novation/design-system/components/form/FormField/FormField';
import { Icon } from '@he-novation/design-system/components/graphics/Icon/Icon';
import { useForwardedRef } from '@he-novation/design-system/hooks/useForwardedRef';
import { DirectionX, DirectionY } from '@he-novation/design-system/utils/getAbsolutePosition';
import cn from 'classnames';
import { useAtomValue, useSetAtom } from 'jotai';
import debounce from 'lodash/fp/debounce';

import { DataLayoutChildren } from '$components/DataLayout/components/DataLayoutChildren/DataLayoutChildren';
import { DataLayoutSorter } from '$components/DataLayout/components/DataLayoutSorter/DataLayoutSorter';
import {
    DATA_LAYOUT_CLASSES,
    DataLayoutColumn,
    DataLayoutGrouper,
    ItemComponent,
    ItemToActions,
    SelectionAtom
} from '$components/DataLayout/DataLayout.types';
import Empty from '$components/Empty/Empty';
import SelectableGroup from '$components/Selectable/SelectableGroup';
import type { SelectionOptions } from '$components/Selectable/SelectionHandler';
import { useAbsoluteMenu } from '$hooks/useAbsoluteMenu';
import { usePanel } from '$hooks/usePanel';
import { useWindowing, WindowingOptions } from '$hooks/useWindowing';

const DATA_LAYOUT_CHILDREN_HORIZONTAL_PADDING: number = 20;

export type DataLayoutProps<T, U extends SelectionAtom | undefined, V> = {
    id: string;
    className?: string;
    iconColor?: string;
    doubleClickHandler?: (e: React.MouseEvent<Element, MouseEvent>, item: T) => void;
    items: T[];
    itemToId: (item: T) => string;
    displayMode?: DataLayoutDisplayMode;
    grouper?: DataLayoutGrouper<T>;
    saveClosedGroupKeys?: string[];
    windowed?: {
        itemHeight: number | ((displayMode: DataLayoutDisplayMode) => number);
        itemWidth:
            | number
            | ((displayMode: DataLayoutDisplayMode, dataLayoutWidth: number) => number);
        itemsPerRow?: number | ((dataLayoutWidth: number) => number);
        minRows?: number | ((displayMode: DataLayoutDisplayMode) => number);
        offset?:
            | [number, number]
            | ((displayMode: DataLayoutDisplayMode) => [number, number] | undefined);
    };
    columns: DataLayoutColumn<T, U, V>[];
    selectionOptions?: SelectionOptions;
    onActiveColumnsChange?: (columns: DataLayoutColumn<T, U, V>[]) => void;
    activeColumns?: string[];
    ItemGridComponent?: ItemComponent<T, U, V>;
    itemToActions?: ItemToActions<T>;
    hideHeader?: boolean;
    selectionAtom: U;
    extra: V;
    initialScroll?: number | string | null;
    onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
    onSort?: (column: DataLayoutColumn<T, U, V>, reversed: boolean) => void;
    sort?: [col: string, reverse: boolean] | null;
    onDrop?: (e: DragEvent) => void;
    emptyComponent?: React.ReactNode;
};

export function accessibilityRole(displayMode: DataLayoutDisplayMode, role: string) {
    return displayMode === DataLayoutDisplayMode.List ? role : undefined;
}

function sortItemlist<T, U extends SelectionAtom | undefined, V>(
    items: T[],
    sorting?: [DataLayoutColumn<T, U, V>, boolean] | null
) {
    let sortedItems: T[];
    if (sorting && sorting.length > 0) {
        sortedItems = items.concat().sort(sorting[0].sort);
        if (sorting[1]) {
            return sortedItems.reverse();
        }
    } else {
        sortedItems = items;
    }
    return sortedItems;
}

const getLocalStorageKey = (id: string) => `${id}-closed-grouper-keys`;

function parseLocalStorageClosedGroups(id: string): string[] {
    const closedGroupKeysString = localStorage.getItem(getLocalStorageKey(id));
    return closedGroupKeysString ? JSON.parse(closedGroupKeysString) : [];
}

function saveLocalStorageClosedGroup(id: string, key: string, closed: boolean) {
    const closedGroupKeys = parseLocalStorageClosedGroups(id);
    if (closed) closedGroupKeys.push(key);
    else closedGroupKeys.splice(closedGroupKeys.indexOf(key), 1);
    localStorage.setItem(getLocalStorageKey(id), JSON.stringify(closedGroupKeys));
}

function DataLayoutInner<T, U extends SelectionAtom | undefined, V>(
    {
        id,
        className,
        items,
        displayMode = DataLayoutDisplayMode.Grid,
        grouper,
        saveClosedGroupKeys,
        windowed: _windowed,
        columns,
        ItemGridComponent,
        selectionOptions,
        onActiveColumnsChange,
        activeColumns: _activeColumns,
        hideHeader,
        extra,
        onScroll,
        initialScroll,
        itemToId,
        onSort,
        selectionAtom,
        sort,
        itemToActions,
        iconColor,
        doubleClickHandler,
        emptyComponent
    }: DataLayoutProps<T, U, V>,
    forwardedRef: React.ForwardedRef<HTMLDivElement>
) {
    const [width, setWidth] = useState(0);
    const ref = useForwardedRef(forwardedRef);
    const [check, setCheck] = useState(false);
    const { panel } = usePanel();
    const emptyRef = useRef<HTMLDivElement>(null);
    const [sorting, setSorting] = useState<
        [col: DataLayoutColumn<T, U, V>, reversed: boolean] | null
    >(sort ? [columns.find((s) => s.key === sort[0])!, sort[1]] : null);
    const panelOpen = !!panel;

    const setSelected = selectionAtom && useSetAtom(selectionAtom);
    const selected: string[] | undefined = selectionAtom && useAtomValue(selectionAtom);

    useEffect(() => {
        if (selected) {
            setCheck(selected.length === items.length);
        }
    }, [selected]);

    const windowed =
        _windowed &&
        (useMemo(() => {
            return {
                itemWidth:
                    (typeof _windowed.itemWidth === 'function'
                        ? _windowed.itemWidth(displayMode, width)
                        : _windowed.itemWidth) +
                    (displayMode === DataLayoutDisplayMode.Grid
                        ? DATA_LAYOUT_CHILDREN_HORIZONTAL_PADDING
                        : 0),
                itemHeight:
                    typeof _windowed.itemHeight === 'function'
                        ? _windowed.itemHeight(displayMode)
                        : _windowed.itemHeight,
                itemsPerRow:
                    displayMode === DataLayoutDisplayMode.Grid
                        ? typeof _windowed.itemsPerRow === 'function'
                            ? _windowed.itemsPerRow(width)
                            : _windowed.itemsPerRow || 4
                        : 1,
                offset:
                    typeof _windowed.offset === 'function'
                        ? _windowed.offset(displayMode)
                        : typeof _windowed.offset === 'undefined'
                          ? displayMode === DataLayoutDisplayMode.Grid
                              ? [10, 0]
                              : undefined
                          : _windowed.offset,
                minRows:
                    typeof _windowed.minRows === 'function'
                        ? _windowed.minRows(displayMode)
                        : _windowed.minRows || 10
            } as WindowingOptions;
        }, [width, _windowed, displayMode, panelOpen]) as WindowingOptions | undefined);

    const windowing = useWindowing(ref, !grouper ? windowed : undefined, items.length);
    const sortedItems = sortItemlist(items, sorting);

    const groupedItemIds = useMemo(() => {
        return (
            grouper ? grouper.grouperFunction(sortedItems).flatMap((g) => g.items) : sortedItems
        ).map((i) => itemToId(i));
    }, [sortedItems, grouper]);

    const sortedSlicedItems = windowing
        ? sortedItems.slice(windowing.visibleRange[0], windowing.visibleRange[1] + 1)
        : sortedItems;
    const [activeColumns, setActiveColumns] = useState<DataLayoutColumn<T, U, V>[]>(
        _activeColumns ? columns.filter((c) => _activeColumns.includes(c.key)) : columns
    );

    const [closedGroupers, setClosedGroupers] = useState<string[]>([]);

    const colMenu = (
        <CheckboxList
            className={cn('columns-menu', formStyles.light)}
            checkboxes={columns
                .filter((c) => !c.hidden && !c.unfilterable && c.header)
                .map((col) => ({
                    id: col.key,
                    checked: !!activeColumns.find((c) => c.key === col.key),
                    label: col.header,
                    value: col.key
                }))}
            onChange={(_, checkboxes) => {
                const newActiveColumns = columns.filter(
                    (column) =>
                        !!checkboxes.find(
                            (c) =>
                                column.hidden ||
                                column.unfilterable ||
                                !column.header ||
                                (c.id === column.key && c.checked)
                        )
                );
                setActiveColumns(newActiveColumns);
                onActiveColumnsChange?.(newActiveColumns);
            }}
        />
    );

    useEffect(() => {
        setActiveColumns((activeColumns) =>
            columns.filter((c) => activeColumns.find((activeColumn) => activeColumn.key === c.key))
        );
    }, [columns]);

    useEffect(() => {
        const unregister: ((() => void) | undefined)[] = [];
        const resize = debounce(100, () => {
            if (ref.current) {
                const style = getComputedStyle(ref.current);
                setWidth(
                    ref.current.scrollWidth -
                        parseFloat(style.paddingLeft) -
                        parseFloat(style.paddingRight) -
                        parseFloat(style.borderLeft) -
                        parseFloat(style.borderRight)
                );
            }
        });
        if (ref.current) {
            resize();
        }

        window.addEventListener('resize', resize);
        if (initialScroll && ref.current) {
            ref.current.scrollTop = Number(initialScroll);
        }
        unregister.push(() => window.removeEventListener('resize', resize));
        return () => unregister.forEach((u) => u?.());
    }, []);

    useEffect(() => {
        setClosedGroupers(parseLocalStorageClosedGroups(id));
    }, [grouper]);

    const dataLayoutChildrenProps = {
        columns: activeColumns.filter((c) => !c.hidden),
        displayMode,
        selectionOptions,
        ItemGridComponent,
        itemToId,
        itemToActions,
        computeItemStyle: windowing?.computeItemStyle,
        offset: windowing ? windowing.visibleRange[0] : 0,
        hideHeader,
        selectionAtom,
        extra,
        doubleClickHandler
    };

    const content = grouper ? (
        grouper.grouperFunction(sortedSlicedItems).map((group) => {
            if (!group.items.length) return null;
            const groupIsClosed = closedGroupers.includes(group.key);
            let sortedGroupItems = group.items;
            if (sorting) {
                sortedGroupItems = group.items.concat().sort(sorting[0].sort);
                if (sorting[1]) {
                    sortedGroupItems.reverse();
                }
            }
            return (
                <section
                    className="group"
                    key={group.key}
                    role={accessibilityRole(displayMode, 'row')}
                >
                    <header className={'header'} role={accessibilityRole(displayMode, 'rowheader')}>
                        <button
                            className={cn('group-button ', !groupIsClosed && 'is-active')}
                            onClick={() => {
                                {
                                    setClosedGroupers(
                                        groupIsClosed
                                            ? closedGroupers.filter((g) => g !== group.key)
                                            : [...closedGroupers, group.key]
                                    );
                                    if (saveClosedGroupKeys?.includes(group.key)) {
                                        saveLocalStorageClosedGroup(id, group.key, !groupIsClosed);
                                    }
                                }
                            }}
                        >
                            <Icon
                                icon={{
                                    name: 'chevron',
                                    stroke: iconColor || 'white'
                                }}
                            />
                            {typeof group.header === 'function'
                                ? group.header(group.key, group.items)
                                : group.header}
                            <span className="results">{group.items.length}</span>
                        </button>
                    </header>
                    <DataLayoutChildren
                        items={sortedGroupItems}
                        {...dataLayoutChildrenProps}
                        className={groupIsClosed ? 'is-hidden' : undefined}
                    />
                </section>
            );
        })
    ) : (
        <DataLayoutChildren
            items={sortedSlicedItems}
            {...dataLayoutChildrenProps}
            style={windowing?.itemWrapperStyle}
        />
    );

    const { openAbsoluteMenu } = useAbsoluteMenu();
    const fullContent = !items.length ? (
        emptyComponent ? (
            emptyComponent
        ) : (
            <Empty ref={emptyRef} />
        )
    ) : (
        <>
            {displayMode === DataLayoutDisplayMode.List && !hideHeader && (
                <>
                    <ul
                        role="row"
                        className="data-layout-cols"
                        onContextMenu={(e) => {
                            e.preventDefault();
                            openAbsoluteMenu(
                                colMenu,
                                [e.clientX, e.clientY],
                                [DirectionX.Right, DirectionY.Bottom]
                            );
                        }}
                    >
                        {setSelected && (
                            <li className="data-layout-select-all">
                                <FormField
                                    id="data-layout-select-all"
                                    type="checkbox"
                                    label={'-'}
                                    checked={check}
                                    onChange={() => {
                                        setSelected((_prev) => {
                                            if (!check) {
                                                return items.map((item) => itemToId(item));
                                            }
                                            return [];
                                        });
                                    }}
                                />
                            </li>
                        )}
                        {activeColumns
                            .filter((c) => !c.hidden)
                            .map((column) => {
                                return (
                                    <li
                                        key={column.key}
                                        role={'columnheader'}
                                        className={`data-layout-col-${column.key.replace(
                                            /\./g,
                                            '-'
                                        )}`}
                                        style={{
                                            width: column.width,
                                            flex: `${column.grow ? 1 : 0} 0 ${
                                                column.width ? `${column.width}px` : ''
                                            }`
                                        }}
                                    >
                                        {column.sort ? (
                                            <button
                                                className={cn(
                                                    'cell-content',
                                                    'sorting-button',
                                                    sorting?.[0].key === column.key &&
                                                        (sorting[1] ? 'asc' : 'desc')
                                                )}
                                                onClick={() => {
                                                    const desc = !!(sorting && !sorting[1]);
                                                    setSorting([column, desc]);
                                                    onSort?.(column, desc);
                                                }}
                                            >
                                                {column.header}
                                                <Icon icon="chevron" />
                                            </button>
                                        ) : (
                                            <div className="cell-content">{column.header}</div>
                                        )}
                                    </li>
                                );
                            })}
                        {itemToActions && <li className="actions-cell"></li>}
                    </ul>
                </>
            )}
            {content}
        </>
    );

    const wrapperProps = {
        id: id,
        className: cn(
            DATA_LAYOUT_CLASSES.WRAPPER,
            `is-${displayMode}`,
            windowing && `is-windowing`,
            className
        ),
        role: accessibilityRole(displayMode, 'table'),
        onScroll,
        style:
            windowing && !grouper
                ? windowing.scrollContainerStyle
                : ({ overflowX: 'auto', overflowY: 'auto' } as CSSProperties)
    };

    return selectionAtom ? (
        <SelectableGroup
            selectionAtom={selectionAtom}
            childrenClassName={DATA_LAYOUT_CLASSES.CHILD}
            DraggedComponent={selectionOptions?.DraggedComponent}
            onItemDrop={selectionOptions?.onItemDrop}
            items={groupedItemIds}
            {...wrapperProps}
            wrapperRef={ref}
        >
            {displayMode === DataLayoutDisplayMode.Grid && (
                <DataLayoutSorter
                    columns={columns}
                    sorting={sorting}
                    setSorting={setSorting}
                    onSort={onSort}
                />
            )}
            {fullContent}
        </SelectableGroup>
    ) : (
        <div {...wrapperProps} ref={ref}>
            {displayMode === DataLayoutDisplayMode.Grid && (
                <DataLayoutSorter
                    columns={columns}
                    sorting={sorting}
                    setSorting={setSorting}
                    onSort={onSort}
                />
            )}
            {fullContent}
        </div>
    );
}

export const DataLayout = React.forwardRef(DataLayoutInner);
