import { asyncWorkspaceUsersFetch } from '@he-novation/front-shared/async/workspace.async';
import { useAtom } from 'jotai';

import { workspaceAdminMembersAtom, workspaceMembersAtom } from '$atoms/workspace-atoms';

export function useWorkspaceMembers() {
    const [workspaceMembers, setWorkspaceMembers] = useAtom(workspaceMembersAtom);

    const fetchWorkspaceMembers = (workspaceName: string) => {
        asyncWorkspaceUsersFetch(workspaceName).then((member) =>
            setWorkspaceMembers(member.sort((a, b) => a.email.localeCompare(b.email)))
        );
    };

    return {
        workspaceMembers,
        fetchWorkspaceMembers
    };
}

export function useWorkspaceAdminMembers() {
    const [workspaceAdminMembers, setWorkspaceAdminMembers] = useAtom(workspaceAdminMembersAtom);

    const fetchWorkspaceAdminMembers = (workspaceName: string) => {
        asyncWorkspaceUsersFetch(workspaceName, true).then((member) =>
            setWorkspaceAdminMembers(member.sort((a, b) => a.email.localeCompare(b.email)))
        );
    };

    return {
        workspaceAdminMembers,
        fetchWorkspaceAdminMembers
    };
}
