import { z } from 'zod';

export const integrationParams = z.object({
    pluginUuid: z.string()
});

export type IntegrationParams = z.infer<typeof integrationParams>;
export const integrationBodySchema = z.object({
    enabled: z.boolean().optional(),
    properties: z.any().optional(),
    secrets: z.any().optional(),
    type: z.string(),
    name: z.string()
});

export type IntegrationBody = z.infer<typeof integrationBodySchema>;

export const integrationAuthSchema = z.object({
    type: z.string()
});

export type integrationAuthSchema = z.infer<typeof integrationAuthSchema>;
