import styles from './ClientCastStyleForm.module.css';
import React, { useCallback, useMemo, useState } from 'react';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { asyncClientDefaultCastStyleSet } from '@he-novation/front-shared/async/workspace.async';
import { useAtomValue } from 'jotai';

import { workspaceAtom, workspaceNameAtom } from '$atoms/workspace-atoms';
import { FormCastDesign } from '$components/form/FormCast/FormCastDesign';
import { useFormCast } from '$components/form/FormCast/useFormCast';
import { useTranslate } from '$hooks/useTranslate';

export function ClientCastStyleForm() {
    const { t } = useTranslate();

    const [submitting, setSubmitting] = useState<boolean>(false);
    const workspaceName = useAtomValue(workspaceNameAtom);
    const workspace = useAtomValue(workspaceAtom)!;

    const {
        logoUserFile,
        setLogoUserFile,
        userFile,
        setUserFile,
        previewData,
        setPreviewData,
        backgroundColor,
        setBackgroundColor
    } = useFormCast(undefined);

    const onSave = useCallback(
        async (e) => {
            e.preventDefault();
            setSubmitting(true);

            await asyncClientDefaultCastStyleSet(workspaceName, {
                backgroundColor: backgroundColor || undefined,
                userFileUid: userFile?.uid,
                logoUserFileUid: logoUserFile?.uid
            });

            setSubmitting(false);
        },
        [userFile, logoUserFile, previewData]
    );

    const _defaultCastStyle = useMemo(
        () => ({
            userFileUid: workspace.defaultCastStyle?.userFileUid ?? undefined,
            logoUserFileUid: workspace.defaultCastStyle?.logoUserFileUid ?? undefined,
            backgroundColor: workspace.defaultCastStyle?.backgroundColor ?? undefined
        }),
        [workspace]
    );

    return (
        <>
            <FormCastDesign
                className={styles.castForm}
                previewData={previewData}
                setPreviewData={setPreviewData}
                setLogoUserFile={setLogoUserFile}
                setUserFile={setUserFile}
                setBackgroundColor={setBackgroundColor}
                defaultCastStyle={_defaultCastStyle}
            />
            <Button tone={ButtonTone.Primary} isLoading={submitting} onClick={onSave}>
                {t('common.Save')}
            </Button>
        </>
    );
}
