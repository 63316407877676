import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import {
    DEFAULT_PASSWORD_CONSTRAINTS,
    PasswordConstraints
} from '@he-novation/config/types/payloads/workspace.payload';
import { UserFile, UserFileType } from '@he-novation/config/types/user-file.types';
import { featureACL } from '@he-novation/config/utils/acl';
import {
    FormField,
    FormFields
} from '@he-novation/design-system/components/form/FormField/FormField';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { asyncWorkspaceAdminClientUpdate } from '@he-novation/front-shared/async/workspace.async';
import {
    asyncWorkspaceUserFileCreate,
    asyncWorkspaceUserFileDelete,
    asyncWorkspaceUserFilesFetch
} from '@he-novation/front-shared/async/workspaceUserFile.async';
import { useAtomValue } from 'jotai';
import _ from 'lodash';

import { workspaceAtom, workspaceNameAtom } from '$atoms/workspace-atoms';
import Accordion from '$components/Accordion/Accordion';
import { SubHeader } from '$components/layout/Header/SubHeader/SubHeader';
import { useTranslate } from '$hooks/useTranslate';
import { ClientOIDCForm } from '$views/settings/SettingsWorkspace/components/ClientOIDCForm';

export function SettingsWorkspaceSecurity() {
    const { t } = useTranslate();

    const workspaceName = useAtomValue(workspaceNameAtom);
    const workspace = useAtomValue(workspaceAtom)!;

    const fieldId = (field: string) => 'settings-client-security-' + field;

    const [watermark, setWatermark] = useState<UserFile>();
    const [passwordConstraints, setPasswordConstraints] = useState<PasswordConstraints>(
        workspace.passwordConstraints || DEFAULT_PASSWORD_CONSTRAINTS
    );
    const [disableDownloads, setDisableDownloads] = useState<boolean>(
        workspace?.disableDownloads || false
    );

    useEffect(() => {
        if (featureACL.watermark(workspace.features)) {
            asyncWorkspaceUserFilesFetch(workspaceName, UserFileType.WATERMARK).then((files) => {
                if (files.length > 0) {
                    setWatermark(files[0]);
                }
            });
        }
    }, [workspace]);

    useEffect(() => {
        setDisableDownloads(!!disableDownloads);
    }, [workspace]);

    useEffect(() => {
        if (
            (passwordConstraints &&
                !_.isEqual(passwordConstraints, workspace.passwordConstraints)) ||
            disableDownloads !== workspace.disableDownloads
        ) {
            asyncWorkspaceAdminClientUpdate(workspaceName, {
                passwordConstraints,
                disableDownloads
            });
        }
    }, [passwordConstraints, workspace, disableDownloads]);

    const onWatermarkAdd = useCallback(
        async (file: File) => {
            if (watermark) {
                await asyncWorkspaceUserFileDelete(workspaceName, watermark.uid).then(() => {
                    setWatermark(undefined);
                });
            }

            asyncWorkspaceUserFileCreate(workspaceName, UserFileType.WATERMARK, file).then(
                (userFile) => {
                    setWatermark(userFile);
                }
            );
        },
        [watermark]
    );

    const canEditPasswordConstraints = featureACL.settings.passwordConstraints(workspace.features);

    return (
        <>
            <SubHeader subClassName={'settings-sub-header'} title={t('settings.Security')} />

            <Accordion title={t('settings.Downloads')} isOpen={true}>
                <FormField
                    formId="settings-preferences"
                    type="checkbox"
                    name="disableDownloads"
                    checked={disableDownloads}
                    label={t('settings.Disable all downloads')}
                    onChange={(e) => setDisableDownloads(e.target.checked)}
                    className="is-switch"
                />
            </Accordion>

            <Accordion title={t('settings.Password constraints')} isOpen={true}>
                {!passwordConstraints && <Loader />}
                {passwordConstraints && (
                    <FormFields
                        formId="settings-preferences"
                        fields={[
                            {
                                className: 'number',
                                type: 'number',
                                id: fieldId('password.constraints.min_length'),
                                name: 'password.constraints.min_length',
                                label: t('settings.Password minimum length'),
                                disabled: !canEditPasswordConstraints,
                                onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                    setPasswordConstraints({
                                        ...passwordConstraints,
                                        constraints: {
                                            ...passwordConstraints.constraints,
                                            min_length:
                                                parseInt(e.currentTarget.value) ||
                                                DEFAULT_PASSWORD_CONSTRAINTS.constraints.min_length
                                        }
                                    }),
                                value: passwordConstraints.constraints.min_length
                            },
                            {
                                className: 'number',
                                type: 'number',
                                id: fieldId('password.constraints.min_lower'),
                                name: 'password.constraints.min_lower',
                                label: t('settings.Minimum number of lower case letters [a-z]'),
                                disabled: !canEditPasswordConstraints,
                                onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                    setPasswordConstraints({
                                        ...passwordConstraints,
                                        constraints: {
                                            ...passwordConstraints.constraints,
                                            min_lower:
                                                parseInt(e.currentTarget.value) ||
                                                DEFAULT_PASSWORD_CONSTRAINTS.constraints.min_lower
                                        }
                                    }),
                                value: passwordConstraints.constraints.min_lower
                            },
                            {
                                className: 'number',
                                type: 'number',
                                id: fieldId('password.constraints.min_upper'),
                                name: 'password.constraints.min_upper',
                                label: t('settings.Minimum number of upper case letters [A-Z]'),
                                disabled: !canEditPasswordConstraints,
                                onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                    setPasswordConstraints({
                                        ...passwordConstraints,
                                        constraints: {
                                            ...passwordConstraints.constraints,
                                            min_upper:
                                                parseInt(e.currentTarget.value) ||
                                                DEFAULT_PASSWORD_CONSTRAINTS.constraints.min_upper
                                        }
                                    }),
                                value: passwordConstraints.constraints.min_upper
                            },
                            {
                                className: 'number',
                                type: 'number',
                                id: fieldId('password.constraints.min_number'),
                                name: 'password.constraints.min_number',
                                label: t('settings.Minimum number of numbers [0-9]'),
                                disabled: !canEditPasswordConstraints,
                                onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                    setPasswordConstraints({
                                        ...passwordConstraints,
                                        constraints: {
                                            ...passwordConstraints.constraints,
                                            min_number:
                                                parseInt(e.currentTarget.value) ||
                                                DEFAULT_PASSWORD_CONSTRAINTS.constraints.min_number
                                        }
                                    }),
                                value: passwordConstraints.constraints.min_number
                            },
                            {
                                className: 'number last',
                                type: 'number',
                                id: fieldId('password.constraints.min_special'),
                                name: 'password.constraints.min_special',
                                label: t(
                                    'settings.Minimum number of special characters [!@#$%^&*]'
                                ),
                                disabled: !canEditPasswordConstraints,
                                onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                    setPasswordConstraints({
                                        ...passwordConstraints,
                                        constraints: {
                                            ...passwordConstraints.constraints,
                                            min_special:
                                                parseInt(e.currentTarget.value) ||
                                                DEFAULT_PASSWORD_CONSTRAINTS.constraints.min_special
                                        }
                                    }),
                                value: passwordConstraints.constraints.min_special
                            }
                        ]}
                    />
                )}
            </Accordion>

            <Accordion title={t('settings.Password expiration')} isOpen={true}>
                {!passwordConstraints && <Loader />}
                {passwordConstraints && (
                    <FormFields
                        formId="settings-preferences"
                        fields={[
                            {
                                type: 'checkbox',
                                id: fieldId('password.expires'),
                                name: 'password.expires',
                                label: t('settings.Passwords should expire'),
                                checked: passwordConstraints.expires,
                                disabled: !canEditPasswordConstraints,
                                onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                    setPasswordConstraints({
                                        ...passwordConstraints,
                                        expires: e.currentTarget.checked
                                    }),
                                className: 'is-switch'
                            },
                            {
                                className: 'number last',
                                type: 'number',
                                id: fieldId('password.expires_days'),
                                name: 'password.expires_days',
                                label: t('settings.Password expiry (days)'),
                                disabled:
                                    !passwordConstraints.expires || !canEditPasswordConstraints,
                                onChange: (e: SyntheticEvent<HTMLInputElement>) =>
                                    setPasswordConstraints({
                                        ...passwordConstraints,
                                        expires_days:
                                            parseInt(e.currentTarget.value) ||
                                            DEFAULT_PASSWORD_CONSTRAINTS.expires_days
                                    }),
                                value: passwordConstraints.expires_days
                            }
                        ]}
                    />
                )}
            </Accordion>

            {featureACL.settings.sso(workspace.features) && (
                <Accordion title={t('settings.OpenID Connect')} isOpen={true}>
                    <ClientOIDCForm />
                </Accordion>
            )}

            <Accordion title={t('settings.Two-factor authentication')} isOpen={true}>
                <FormField
                    formId="settings-preferences"
                    type="checkbox"
                    name="twoFactorAuthentication"
                    checked={workspace.totpForced}
                    label={t('settings.Force two-factor authentication')}
                    onChange={async (e) => {
                        await asyncWorkspaceAdminClientUpdate(workspaceName, {
                            totpForced: e.target.checked,
                            defaultCastTeamAccess: workspace.defaultCastTeamAccess,
                            disableProjectCasts: workspace.disableProjectCasts
                        });
                    }}
                    className="is-switch"
                />
            </Accordion>
            {featureACL.watermark(workspace.features) && (
                <Accordion title={t('folder.Watermark')} isOpen={true}>
                    <FormField
                        name="client-watermark"
                        id={fieldId('client-watermark')}
                        type={'graphic-picker'}
                        value={watermark}
                        values={[]}
                        onAdd={onWatermarkAdd}
                    />
                </Accordion>
            )}
        </>
    );
}
