import React, { useEffect, useState } from 'react';
import { ModularForm } from 'react-modular-forms';
import { ClientOIDC } from '@he-novation/config/types/client.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import { FormFields } from '@he-novation/design-system/components/form/FormField/FormField';
import { Theme } from '@he-novation/design-system/enums';
import {
    asyncClientOIDCFetch,
    asyncClientOIDCUpdate
} from '@he-novation/front-shared/async/workspace.async';
import { useAtomValue } from 'jotai';
import { workspaceNameAtom } from '../../../../atoms/workspace-atoms';

import { useTranslate } from '$hooks/useTranslate';

export function ClientOIDCForm() {
    const [oidc, setOIDC] = useState<ClientOIDC | null>(null);
    const [secret, setSecret] = useState<string | null>('');
    const [PKCE, setPKCE] = useState<boolean>(false);
    const workspaceName = useAtomValue(workspaceNameAtom);

    useEffect(() => {
        asyncClientOIDCFetch(workspaceName).then((r) => {
            setOIDC(r);
            setSecret(r?.clientSecret || '');
            setPKCE(r?.clientSecret ? r.PKCE : true);
        });
    }, []);

    const { t } = useTranslate();

    return (
        <ModularForm
            id="client-oidc-form"
            onSubmit={async (e, data) => {
                e.preventDefault();
                asyncClientOIDCUpdate(workspaceName, {
                    issuer: data.issuer || '',
                    clientId: data.clientId || '',
                    clientSecret: data.clientSecret || '',
                    PKCE: data.clientSecret ? data.PKCE : true,
                    button: data.button || '',
                    strict: !!data.strict
                });
            }}
        >
            <FormFields
                formId="client-oidc-form"
                theme={Theme.Dark}
                fields={[
                    {
                        label: t('settings.OIDC issuer URL'),
                        type: 'text',
                        name: 'issuer',
                        value: oidc?.issuer
                    },
                    {
                        label: t('settings.OIDC client ID'),
                        type: 'text',
                        name: 'clientId',
                        value: oidc?.clientId
                    },
                    {
                        label: t('settings.OIDC client secret'),
                        type: 'text',
                        name: 'clientSecret',
                        value: secret,
                        onChange: (e) => {
                            setSecret(e.currentTarget.value);
                            if (!e.currentTarget.value) setPKCE(true);
                        }
                    },
                    {
                        type: 'checkbox',
                        name: 'PKCE',
                        className: 'is-switch',
                        label: 'OIDC PKCE',
                        checked: PKCE,
                        disabled: !secret,
                        onChange: (e) => {
                            setPKCE(e.currentTarget.checked);
                        }
                    },
                    {
                        type: 'text',
                        name: 'button',
                        label: t('settings.OIDC button text'),
                        value: oidc?.button
                    },
                    {
                        type: 'checkbox',
                        name: 'strict',
                        className: 'is-switch',
                        label: t('settings.Disable all connections except OIDC'),
                        checked: oidc?.strict || false
                    }
                ]}
            />

            <Button type="submit" tone={ButtonTone.Primary} style={{ marginTop: 20 }}>
                {t('common.Save')}
            </Button>
        </ModularForm>
    );
}
