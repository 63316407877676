import styles from './Settings.module.css';
import { ReactNode, useEffect } from 'react';

import { useTitleLocation } from '$hooks/useDocumentTitle';
import { useTranslate } from '$hooks/useTranslate';
import { SettingsUser } from '$views/settings/SettingsUser/SettingsUser';
import { SettingsWorkspace } from '$views/settings/SettingsWorkspace/SettingsWorkspace';

export enum SettingsMode {
    CLIENT = 'client',
    USER = 'user'
}

type SettingsProps = {
    route: string;
    mode: SettingsMode;
};

export default function Settings({ route, mode }: SettingsProps) {
    const { t } = useTranslate();
    const { setLocation } = useTitleLocation();
    useEffect(() => setLocation(t('common.Settings')), []);

    let content: ReactNode;
    switch (mode) {
        case SettingsMode.CLIENT:
            content = <SettingsWorkspace route={route} />;
            break;
        default:
            content = <SettingsUser route={route} />;
            break;
    }

    return <div className={styles.settings}>{content}</div>;
}
