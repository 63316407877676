import styles from './SettingsPluginsPlugin.module.css';
import React from 'react';
import { PLUGIN_CONFIGURATION } from '@he-novation/config/paths/modals.constants';
import type { Integration } from '@he-novation/config/types/integration.types';
import { Button, ButtonTone } from '@he-novation/design-system/components/buttons/Button/Button';
import cn from 'classnames';
import { useModal } from '../../../../hooks/useModal';
import { useUserIntegrations } from '../../../../hooks/useUserIntegrations';

import { useTranslate } from '$hooks/useTranslate';

type SettingsPluginsPluginProps = {
    type: string;
    configurable: boolean;
    oauth: boolean;
    configurations: Integration[];
};

export function SettingsPluginsPlugin({
    type,
    configurable,
    oauth,
    configurations
}: SettingsPluginsPluginProps) {
    const { openModal } = useModal();
    const { t } = useTranslate();
    const { deleteUserIntegration, fetchIntegrationAuthUrl } = useUserIntegrations();
    return (
        <li className={cn('c-settings-plugins-plugin')} key={type}>
            <div className={styles.plugin}>
                <img
                    className={styles.pluginIcon}
                    src={`/assets/img/plugin-${type}.png`}
                    alt={type}
                />

                <div className={styles.pluginMain}>
                    <h2 className={styles.pluginTitle}>{type.toUpperCase()}</h2>

                    <div className={styles.pluginDescription}>
                        <p>{t(`settings.__PLUGIN_TEXT_${type.toUpperCase()}`)}</p>

                        {type === 'youtube' && (
                            <ul>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://www.youtube.com/t/terms"
                                        rel="noreferrer"
                                    >
                                        {t("plugins.Youtube's terms of services")}
                                    </a>
                                </li>
                                <li>
                                    <a
                                        target="_blank"
                                        href="https://policies.google.com/privacy"
                                        rel="noreferrer"
                                    >
                                        {t('plugins.Google privacy policy')}
                                    </a>
                                </li>
                                {configurations.length > 0 && (
                                    <li>
                                        <a
                                            target="_blank"
                                            href="https://security.google.com/settings/security/permissions."
                                            rel="noreferrer"
                                        >
                                            {t('plugins.Google security settings')}
                                        </a>
                                    </li>
                                )}
                            </ul>
                        )}
                    </div>
                </div>

                {configurable && (
                    <div className={styles.pluginButtons}>
                        <Button
                            tone={ButtonTone.Primary}
                            onClick={() =>
                                !oauth
                                    ? openModal(
                                          PLUGIN_CONFIGURATION,
                                          {
                                              plugin: type
                                          },
                                          null,
                                          true
                                      )
                                    : fetchIntegrationAuthUrl(type)
                            }
                        >
                            {t(oauth ? 'plugins.Connect an account' : 'plugins.Add configuration')}
                        </Button>
                    </div>
                )}
            </div>

            {configurations.length > 0 && (
                <div className={styles.pluginSettings}>
                    <h2>{t('settings.Configurations')}</h2>
                    <ul>
                        {configurations.map((c) => (
                            <li key={c.uuid}>
                                <span>{c.name}</span>

                                <Button
                                    className={styles.pluginSettingsEdit}
                                    tone={ButtonTone.Outlined}
                                    icon="pencil"
                                    onClick={() => {
                                        openModal(
                                            PLUGIN_CONFIGURATION,
                                            {
                                                plugin: c.type,
                                                name: c.name,
                                                properties: c.properties,
                                                uuid: c.uuid,
                                                enabled: c.enabled
                                            },
                                            null,
                                            true
                                        );
                                    }}
                                />

                                <Button
                                    tone={ButtonTone.Alert}
                                    icon="trash"
                                    onClick={() => deleteUserIntegration(c.uuid)}
                                />
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </li>
    );
}
