import React, { Suspense } from 'react';
import {
    ADMIN,
    API_DOCS,
    AVID_FILE_SUMMARY,
    CAST,
    CAST_FILE,
    CAST_FOLDER,
    CASTS,
    DASHBOARD,
    DIRECTORY,
    FILE,
    FILE_COMPARE,
    FILE_COMPARE_LEGACY,
    FILE_LEGACY,
    FOLDER,
    PDF_FILE_SUMMARY,
    PDF_FOLDER_SUMMARY,
    PLANNING,
    PROJECT,
    PROJECT_CAST,
    PROJECT_FOLDERS,
    PROJECT_SEARCH,
    PROJECTS,
    SEARCH,
    SETTINGS,
    SETTINGS_WORKSPACE,
    STRIPE_PAYMENT_ISSUE,
    TASKS,
    TRASH,
    WORKSPACE_CREATION,
    WORKSPACE_UPGRADE
} from '@he-novation/config/paths/herawFrontUris';
import { UserWorkspace } from '@he-novation/config/types/user.types';
import { WorkspaceFeature } from '@he-novation/config/types/workspace.types';
import { Loader } from '@he-novation/design-system/components/widgets/Loader/Loader';
import { useAtomValue } from 'jotai';
import { workspaceAtom } from './atoms/workspace-atoms';

import { NoWorkspace } from '$views/NoWorkspace/NoWorkspace';
import { SettingsMode } from '$views/settings/Settings';

const Admin = React.lazy(() => import('$views/Admin/Admin'));
const ApiDocs = React.lazy(() => import('$views/docs/ApiDocs/ApiDocs'));

const Cast = React.lazy(() => import('$views/cast/Cast/Cast'));
const CastsContainer = React.lazy(() => import('$views/cast/Casts/Casts'));

const CastFile = React.lazy(() => import('$views/cast/CastFile/CastFile'));
const CastFolder = React.lazy(() => import('$views/cast/CastFolder/CastFolderView'));

const Dashboard = React.lazy(
    () => import(/* webpackChunkName: "dashboard" */ '$views/dashboard/Dashboard')
);

const Directory = React.lazy(
    () => import(/* webpackChunkName: "contacts" */ './views/directory/Directory')
);
const File = React.lazy(() => import(/* webpackChunkName: "file" */ './views/file/FileContainer'));
const Folder = React.lazy(() => import(/* webpackChunkName: "folder" */ './views/folder/Folder'));
const Search = React.lazy(() => import(/* webpackChunkName: "search" */ './views/search/Search'));
const Projects = React.lazy(
    () => import(/* webpackChunkName: "projects" */ './views/projects/Projects/Projects')
);

const Planning = React.lazy(
    () => import(/* webpackChunkName: "planning" */ '$views/planning/Planning')
);
const Project = React.lazy(
    () => import(/* webpackChunkName: "project" */ './views/projects/Project/Project')
);

const Settings = React.lazy(
    () => import(/* webpackChunkName: "settings" */ './views/settings/Settings')
);

const SettingsWorkspaceRedirect = React.lazy(
    () => import('./views/settings/SettingsWorkspace/SettingsWorkspaceRedirect')
);

const Summary = React.lazy(
    () => import(/* webpackChunkName: "summary" */ '$views/summary/file/FileSummary')
);
const SummaryAvid = React.lazy(
    () => import(/* webpackChunkName: "summary-avid" */ '$views/summary/file/SummaryAvid')
);

const FolderSummary = React.lazy(
    () => import(/* webpackChunkName: "folder-summary" */ '$views/summary/folder/FolderSummary')
);

const Tasks = React.lazy(() => import(/* webpackChunkName: "tasks" */ '$views/tasks/Tasks'));

const Trash = React.lazy(() => import(/* webpackChunkName: "trash" */ '$views/trash/Trash'));
const WorkspaceCreation = React.lazy(
    () => import(/* webpackChunkName: "trash" */ '$views/workspace/WorkspaceCreation')
);
const WorkspaceUpgrade = React.lazy(
    () => import(/* webpackChunkName: "trash" */ '$views/workspace/WorkspaceUpgrade')
);

/* eslint-disable */
export default {
    '/': {
        Component: ({ workspace }: { workspace: UserWorkspace | null }) => (
            <Suspense fallback={<Loader />}>
                {workspace ? (
                    workspace.features.includes(WorkspaceFeature.DAM) ? (
                        <Folder />
                    ) : (
                        <Projects />
                    )
                ) : (
                    <NoWorkspace />
                )}
            </Suspense>
        )
    },
    [ADMIN]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Admin route={params.route} />
            </Suspense>
        )
    },
    [CASTS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <CastsContainer />
            </Suspense>
        )
    },
    [CAST]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Cast castUid={params.castUid} castFolderUuid={params.castFolderUuid} />
            </Suspense>
        )
    },
    [DASHBOARD]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Dashboard />
            </Suspense>
        )
    },
    [DIRECTORY]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Directory route={params.route} subroute={params.subroute} />
            </Suspense>
        )
    },
    [FILE]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <File shared={false} uuid={params.fileUuid} />
            </Suspense>
        )
    },
    [FILE_COMPARE]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <File shared={false} />
            </Suspense>
        )
    },
    // @deprecated
    [FILE_LEGACY]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <File shared={false} uuid={params.fileUuid} />
            </Suspense>
        )
    },
    // @deprecated
    [FILE_COMPARE_LEGACY]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <File shared={false} />
            </Suspense>
        )
    },
    [FOLDER]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Folder uuid={params.folderUuid} />
            </Suspense>
        )
    },
    [PLANNING]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Planning />
            </Suspense>
        )
    },
    [PROJECTS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Projects />
            </Suspense>
        )
    },
    [PROJECT]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Project
                    uuid={params.uuid}
                    route={params.route}
                    task={queryParams.task}
                    search={queryParams.q}
                />
            </Suspense>
        )
    },
    [PROJECT_CAST]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Project
                    uuid={params.uuid}
                    route="cast"
                    castUid={params.castUid}
                    folderUuid={params.castFolderUuid}
                />
            </Suspense>
        )
    },
    [PROJECT_SEARCH]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Project uuid={params.uuid} route="search" search={queryParams.q} />
            </Suspense>
        )
    },
    [PROJECT_FOLDERS]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Project uuid={params.uuid} route="folders" task={queryParams.task} />
            </Suspense>
        ),
        ignoreParamsInKey: true
    },
    [SEARCH]: {
        Component: ({ route: { queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Search
                    folder={
                        queryParams.folderUuid && queryParams.folderName
                            ? {
                                  uuid: queryParams.folderUuid,
                                  name: queryParams.folderName
                              }
                            : undefined
                    }
                    search={queryParams.q}
                />
            </Suspense>
        )
    },
    [SETTINGS]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Settings route={params.route} mode={params.mode as SettingsMode} />
            </Suspense>
        )
    },
    [SETTINGS_WORKSPACE]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Settings route={params.route} mode={SettingsMode.CLIENT} />
            </Suspense>
        )
    },
    [STRIPE_PAYMENT_ISSUE]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <SettingsWorkspaceRedirect />
            </Suspense>
        )
    },
    [TASKS]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <Tasks />
            </Suspense>
        )
    },
    [TRASH]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <Trash folderUuid={params.folderUuid} />
            </Suspense>
        )
    },
    [WORKSPACE_CREATION]: {
        Component: () => (
            <Suspense fallback={<Loader />}>
                <WorkspaceCreation />
            </Suspense>
        )
    },
    [WORKSPACE_UPGRADE]: {
        Component: ({ route: { queryParams } }) => {
            const step =
                typeof queryParams.step !== undefined ? parseInt(queryParams.step) : undefined;
            const workspace = useAtomValue(workspaceAtom);

            return (
                <Suspense fallback={<Loader />}>
                    <WorkspaceUpgrade
                        workspace={workspace!}
                        step={step && !isNaN(step) ? step : undefined}
                    />
                </Suspense>
            );
        }
    }
};

export const castRoutes = {
    [CAST_FOLDER]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <CastFolder castUid={params.castUid} castFolderUuid={params.castFolderUuid} />
            </Suspense>
        ),
        ignoreParamsInKey: true
    },
    [CAST_FILE]: {
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <CastFile routeParams={params} />
            </Suspense>
        )
    }
};

export const docsRoutes = {
    [API_DOCS]: {
        ignoreParamsInKey: true,
        Component: ({ route: { params } }) => (
            <Suspense fallback={<Loader />}>
                <ApiDocs groupSlug={params.groupSlug} version={params.version} />
            </Suspense>
        )
    }
};

export const summaryRoutes = {
    [PDF_FILE_SUMMARY]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <Summary
                    fileUuid={params.fileUuid}
                    fileVersion={params.fileVersion}
                    filters={queryParams?.filters}
                    print={true}
                />
            </Suspense>
        )
    },
    [AVID_FILE_SUMMARY]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <SummaryAvid
                    file={{ uuid: params.fileUuid, version: parseInt(params.fileVersion) }}
                    filters={queryParams?.filters}
                    lineBreak={queryParams?.bl}
                />
            </Suspense>
        )
    },
    [PDF_FOLDER_SUMMARY]: {
        Component: ({ route: { params, queryParams } }) => (
            <Suspense fallback={<Loader />}>
                <FolderSummary folderUuid={params.folderUuid} />
            </Suspense>
        )
    }
};

/* eslint-enable */
